import React, { Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import {
  Layout,
  Hero,
  HTMLContent,
  Breadcrumbs,
  DocAttribution,
  RelatedPostTile,
  SocialShare,
} from '../components'
import { featuredImgProps } from '../proptypes'
import { removeTrailingSlash, getValidDates } from '../utils'

const PostTemplate = ({
  title,
  featuredImage,
  slug,
  url,
  html,
  date,
  dateModified,
  related,
  twitterHandle,
}) => {
  return (
    <div
      id="content"
      className={`content content-post post-${slug
        .split('/')
        .filter((i) => i)
        .join('-')}`}
    >
      <Breadcrumbs title={title} slug={slug} />
      <Hero header={title} featuredImage={featuredImage} />
      <div id="inner-content" className="cf">
        <div className="byline-holder">
          <div className="byline">
            <time className="post-time" dateTime={date.format('YYYY-MM-DD')}>
              Posted: {date.format('MMMM D, YYYY')}
            </time>
            {dateModified.isValid() &&
              !dateModified.startOf('day').isSame(date.startOf('day')) && (
                <Fragment>
                  <span style={{ padding: '0 0.5em' }}>|</span>
                  <time
                    className="post-time"
                    dateTime={dateModified.format('YYYY-MM-DD')}
                  >
                    Updated: {dateModified.format('MMMM D, YYYY')}
                  </time>
                </Fragment>
              )}
          </div>
          <DocAttribution />
        </div>
        <article className="post-content cf">
          <HTMLContent content={html} />
          <SocialShare url={url} title={title} twitterHandle={twitterHandle} />
          {!!related && related.length && (
            <div id="related-posts">
              <hr style={{ margin: '2rem auto 1rem auto' }} />
              <h3>Related Posts</h3>
              <div className="grid">
                {related.map(
                  ({
                    fields: { slug, imageBlog },
                    frontmatter: { title, featuredImage },
                  }) => (
                    <RelatedPostTile
                      key={uuidv4()}
                      title={title}
                      slug={slug}
                      src={imageBlog}
                      alt={
                        !!featuredImage && !!featuredImage.alt
                          ? featuredImage.alt
                          : null
                      }
                    />
                  ),
                )}
              </div>
            </div>
          )}
        </article>
      </div>
    </div>
  )
}

PostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  featuredImage: featuredImgProps,
  slug: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(moment().constructor),
  dateModified: PropTypes.instanceOf(moment().constructor),
  related: PropTypes.array,
  twitterHandle: PropTypes.string.isRequired,
}

const Post = ({ data }) => {
  const {
    html,
    fields: { gitAuthorTime, gitCreatedTime, slug, imageBlog },
    frontmatter: { title, featuredImage, date },
  } = data.markdownRemark
  const { location, twitterHandle } = data.site.siteMetadata
  location.slug = removeTrailingSlash(slug)
  const dates = getValidDates(date, gitAuthorTime, gitCreatedTime)
  if (featuredImage) {
    featuredImage.blog = imageBlog
  }
  const postProps = {
    title,
    twitterHandle,
    featuredImage,
    slug,
    url: removeTrailingSlash(`${location.origin}${slug}`),
    html,
    date: dates.date,
    dateModified: dates.dateModified,
    related: data.related.edges
      .filter(
        ({ node }) =>
          removeTrailingSlash(node.fields.slug) !== removeTrailingSlash(slug),
      )
      .slice(0, 4)
      .map(({ node }) => ({ ...node })),
  }
  return (
    <Layout location={location} data={data}>
      <PostTemplate {...postProps} />
    </Layout>
  )
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Post

export const PostQuery = graphql`
  query PostTemplate($id: String!, $category: String!) {
    site {
      ...siteMeta
    }
    related: allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: $category } } }
      limit: 5
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            imageBlog {
              childImageSharp {
                fluid(
                  maxWidth: 225
                  maxHeight: 225
                  quality: 80
                  cropFocus: CENTER
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          frontmatter {
            title
            featuredImage {
              alt
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        gitAuthorTime
        gitCreatedTime
        imageBlog {
          ...blogHeroImage
        }
        inlineImages {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
      }
      frontmatter {
        ...seoFields
        date(formatString: "YYYY-MM-DD")
        ...featuredImageFields
        templateKey
      }
      html
    }
  }
`
